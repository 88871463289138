import React, { useState } from 'react';
//import * as React from 'react'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractRead,
  } from 'wagmi'
import Web3, { ResponseError , getTransactionCount, eth} from 'web3'
import * as Abiconfig from './Abiconfig'

import  { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';

function HighlightDivs ({ ABI, contractAddress}) {

    

    
    const [referral, setReferral] = useState('0x0000000000000000000000000000000000000000');
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      // Получить параметры из адресной строки
      const queryParams = new URLSearchParams(location.search);
      const referralParam = queryParams.get('ref');
  
      if (referralParam) {
        // Сохранить реферальную ссылку в состоянии
        setReferral(referralParam);
  
        // Удалить реферальную ссылку из адресной строки
        queryParams.delete('ref');
      navigate({ search: queryParams.toString() }, { replace: true });
    } 
  }, [location.search, navigate]);
////////////



    const [selectedDiv, setSelectedDiv] = useState(1);

    const handleDivClick = (index) => {
      setSelectedDiv(index);
    };


      let payValue = 0.0022;


      const { config, refetch } = usePrepareContractWrite({
        address: '0x630c009109e4174fcad1db00b980e04a60d2ca61', //Abiconfig.contractAddress,!!!!!!!!!!!!!!!!
        abi: Abiconfig.ABI,        
        functionName: 'mint',        
        chainId: 8453,        
        overrides: { value: ethers.utils.parseEther((payValue * selectedDiv).toString()) }, 
        args: [ethers.BigNumber.from(selectedDiv), ethers.utils.getAddress(referral)],
        watch: true,
        cacheOnBlock: true,

    })

    console.log('config:',config);

    const {  data, write } =  useContractWrite(config)
    const checkMinted = usePrepareContractWrite(config)
    const { isLoading, isSuccess } = useWaitForTransaction({
      hash: data?.hash,
    })
    
    console.log (referral);
    console.log('useContractWrite:',data);
    console.log('write:',write);
    console.log('checkMinted:',checkMinted);


    
    const {data:minted} = useContractRead({
      address: '0x630c009109e4174fcad1db00b980e04a60d2ca61', //////Abiconfig.contractAddress!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            abi: ABI,
            functionName: 'totalSupply',        
            chainId: 8453,            
    });

    console.log(minted);

    let totalMinted=0;
    let supplyCheck = true;
   if (minted != null) {
    console.log(Web3.utils.hexToNumber(minted._hex)); 
    totalMinted = Web3.utils.hexToNumber(minted._hex);
    if (totalMinted<1000) {supplyCheck=false}
   }

  

 

  return (
    <div>
    <div className="div-block-4">
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={index}
          className={`box ${selectedDiv === index ? 'howmany w-button howmanychecked' : 'howmany w-button'}`}
          onClick={() => handleDivClick(index)}
        >
                  {index}
        </div>
      ))}
    </div>
	<div className="div-block-5">
               
        <button className='button w-button'   disabled={ !write || isLoading ||isSuccess|| !checkMinted.isSuccess   } onClick={() => write()}>
                   
        {!write ? checkMinted.isError? checkMinted.error.message.substring(0, 19) :'MINT': isLoading  ? 'MINTING...' : 'MINT'}  

           


      </button>
     
    </div>    
    </div>




  );
};

export default HighlightDivs;
